<template>
  <div class="akt-detail">
    <div class="columns no-margin">
      <div class="column no-padding is-full">
        <div class="card">
          <header class="card-header has-background-primary">
            <div @click.stop="$router.go(-1)" class="card-header-icon">
              <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
            </div>
            <p class="card-header-title has-text-light">KASUS DETAIL</p>
            <template v-if="isLoaded && aktivitas.extra_data.can_change">
              <router-link
                :to="{ name: 'aktivitas-change', params: { id: aktivitas.id } }"
                class="card-header-icon has-text-light"
              >
                <b-icon class="has-text-light" size="is-small" icon="edit" />
              </router-link>
              <a class="card-header-icon" @click.stop.prevent="confirmDelete">
                <b-icon class="has-text-light" size="is-small" icon="trash" />
              </a>
            </template>
          </header>
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Rotasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.stase }}</div>
            </div>
            <template v-if="aktivitas.perawatan">
              <div class="columns is-mobile">
                <div class="column detail has-text-weight-semibold is-4">Kode Bill</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ aktivitas.perawatan.kode_bill }}</div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail is-4 has-text-weight-semibold">Pasien</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left is-capitalized">
                  <router-link
                    v-if="aktivitas.perawatan.pasien.id"
                    :to="{ name: 'pasien-detail', params: { id: aktivitas.perawatan.pasien.id } }"
                  >{{ aktivitas.perawatan.pasien.nama }}</router-link>
                </div>
              </div>
              <div class="columns is-mobile">
                <div class="column detail is-4 has-text-weight-semibold">No RM</div>
                <div class="column detail has-text-weight-semibold is-1">:</div>
                <div class="column detail has-text-left">{{ aktivitas.perawatan.pasien.no_rm }}</div>
              </div>
            </template>
            <div v-if="aktivitas.lokasi" class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Lokasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.lokasi.nama }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Dosen</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.preceptor.nama }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Tanggal</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.tanggal }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Mahasiswa</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ aktivitas.pspd }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Materi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div
                class="column detail has-text-left"
              >{{ aktivitas.kompetensi.kuantitatif }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Uraian</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div
                class="column detail has-text-left"
              >{{ aktivitas.catatan }}</div>
            </div>
            <template v-if="canVerifikasi">
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Verifikasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <div class="no-padding">
                  <b-select v-model="aktivitas.verifikasi_input" @input="validateInput('verifikasi_input')">
                    <option value="Belum">Ver: -</option>
                    <option value="Ya">Ver: ✓</option>
                    <option value="Tidak">Ver: ✗</option>
                  </b-select>
                </div>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold"></div>
              <div class="column detail has-text-weight-semibold is-1"></div>
              <div class="column detail has-text-left">
                <b-button
                  class="is-primary has-text-weight-semibold"
                  @click.stop.prevent="saveObject"
                  :disabled="!isValid"
                >Simpan</b-button>
              </div>
            </div>
            </template>
            <div v-else class="columns is-mobile">
              <div class="column detail is-4 has-text-weight-semibold">Verifikasi</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">
                <hasil-mark :hasil="aktivitas.verifikasi"></hasil-mark>
              </div>
            </div>
            <div
              v-if="aktivitas.komponen_foto_list && aktivitas.komponen_foto_list.length > 0"
              class="columns is-mobile"
            >
              <div class="column">
                <a
                  @click.stop.prevent="openGallery"
                  class="is-pulled-right primary-btn card-header-icon"
                >
                  <b-icon title="Tampilkan gambar" custom-size="2x" size="is-small" icon="image" />
                </a>
              </div>
            </div>
            <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isLoaded">
      <div class="columns diagnosis-container">
        <div class="column is-full">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Diagnosis</p>
              <router-link
                v-if="aktivitas.extra_data.can_add_komponen"
                :to="{ name: 'aktivitas-diagnosis-add', params: { aktivitasId: aktivitas.id } }"
                class="card-header-icon has-text-black"
              >
                <b-icon size="is-small" icon="plus" />
              </router-link>
            </header>
            <div class="content card-content">
              <div class="columns is-mobile">
                <div class="column">
                  <div v-if="aktivitas.diagnosis_set.length > 0">
                    <b-field grouped group-multiline>
                      <komponen-aktivitas-tag
                        v-for="ak_diag in aktivitas.diagnosis_set"
                        :key="ak_diag.id"
                        :id="ak_diag.id"
                        :namedUrl="'aktivitas-diagnosis-detail'"
                        :aktivitas="aktivitas"
                        :aktKomp="ak_diag"
                        :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                      />
                    </b-field>
                  </div>
                  <div v-else>Tidak ada data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Terapi</p>
              <router-link
                v-if="aktivitas.extra_data.can_add_komponen"
                :to="{ name: 'aktivitas-pemeriksaan-add', params: { aktivitasId: aktivitas.id } }"
                class="card-header-icon has-text-black"
              >
                <b-icon size="is-small" icon="plus" />
              </router-link>
            </header>
            <div class="content card-content">
              <div class="columns is-mobile">
                <div class="column">
                  <div v-if="aktivitas.pemeriksaan_set.length > 0">
                    <b-field grouped group-multiline>
                      <komponen-aktivitas-tag
                        v-for="ak_pem in aktivitas.pemeriksaan_set"
                        :key="ak_pem.id"
                        :id="ak_pem.id"
                        :namedUrl="'aktivitas-pemeriksaan-detail'"
                        :aktivitas="aktivitas"
                        :aktKomp="ak_pem"
                        :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                      />
                    </b-field>
                  </div>
                  <div v-else>Tidak ada data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">Pengendalian dan Pencegahan</p>
              <router-link
                v-if="aktivitas.extra_data.can_add_komponen"
                :to="{ name: 'aktivitas-tatalaksana-add', params: { aktivitasId: aktivitas.id } }"
                class="card-header-icon has-text-black"
              >
                <b-icon size="is-small" icon="plus" />
              </router-link>
            </header>
            <div class="content card-content">
              <div class="columns is-mobile">
                <div class="column">
                  <div v-if="aktivitas.tatalaksana_set.length > 0">
                    <b-field grouped group-multiline>
                      <komponen-aktivitas-tag
                        v-for="ak_tata in aktivitas.tatalaksana_set"
                        :key="ak_tata.id"
                        :id="ak_tata.id"
                        :namedUrl="'aktivitas-tatalaksana-detail'"
                        :aktivitas="aktivitas"
                        :aktKomp="ak_tata"
                        :tagColor="getTagColor(aktivitas.pspd_id, aktivitas.preceptor.id)"
                      />
                    </b-field>
                  </div>
                  <div v-else>Tidak ada data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import GLightbox from "glightbox";
import { mapGetters, mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Aktivitas from "../models/aktivitasDetail.js";

export default {
  name: "AktivitasDetail",
  props: ["title"],
  mixins: [createUpdateMixin],
  components: {
    HasilMark: () => import("@/apps/core/components/HasilMark.vue"),
    KomponenAktivitasTag: () =>
      import("@/apps/aktivitas/components/KomponenAktivitasTag.vue"),
  },
  data() {
    this.aktivitasMdl = new Aktivitas();
    this.objectMdl = this.aktivitasMdl;
    let obv = this.aktivitasMdl.getObservables();
    obv.saved = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPSPD", "isPreceptor"]),
    canVerifikasi() {
      return (
        this.userId == this.aktivitas.preceptor_id
      );
    }
  },
  methods: {
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: `Anda yakin akan <b>menghapus</b> ${this.title.toLowerCase()} ini?`,
        confirmText: `Hapus ${this.title}`,
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.objectMdl.delete(this.$route.params.id, (data) => {
            data.redirect_url? this.$router.replace({"name": data.redirect_url}) : this.$router.go(-1);
          }
          );
        }
      });
    },
    // onLoaded() {
    //   const apiURL = `${this.aktivitasMdl.getUpdateUrl()}verifikasi/`;
    //   this.aktivitasMdl.setUpdateUrl(apiURL);
    // },
    fetchData() {
      this.aktivitasMdl.load(this.$route.params.id, this.onLoaded);
    },
    validateInput(field) {
      this.objectMdl.validate(field);
      this.saved = false;
    },
    onSaved(data) {
      this.saved = true;
      this.aktivitas.verifikasi = data.verifikasi;
      this.aktivitas.waktu_verifikasi = data.waktu_verifikasi;
      this.aktivitasMdl.setEdited(false);
    },
    // onSaved() {
    //   this.aktivitas.verifikasi = this.aktivitas.verifikasi_input;
    //   let aktivitasSet = this.aktivitas.keterampilan_set.filter(
    //     ak => ak.verifikasi === "Belum"
    //   );
    //   for (let akt of aktivitasSet) {
    //     akt.verifikasi = this.aktivitas.verifikasi;
    //   }
    // },
    getTagColor(pspd_id, preceptor_id) {
      return this.userId == pspd_id || this.userId == preceptor_id;
    },
    openGallery() {
      this.glightbox.setElements(this.aktivitas.komponen_foto_list);
      this.glightbox.open();
    },
  },
  watch: {
    aktivitas: {
      // aktivitas berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.saved && !this.aktivitasMdl.getEdited()) {
          this.aktivitasMdl.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  }
};
</script>

<style scoped lang="scss">
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
.diagnosis-container {
  margin-top: .5rem;
}

::v-deep .is-grouped-multiline {
  margin-bottom: unset !important;
}

table td.add-btn {
  max-width: 50px;
  text-align: right;

  a {
    padding-top: 2px;
  }
}

.float {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #00d1b2;
  border-radius: 50px;
  text-align: center;
}

.primary-btn {
  color: #cd9777 !important;
}
</style>
