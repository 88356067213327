import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktivitas";
const modelPath = `/${modelName}/`;

class Aktivitas extends ModelBase {
  constructor() {
    let model = {
        id: null,
        perawatan: {
          id: null,
          kode_bill: "",
          jenis_lokasi: "",
          pasien: {
            id: null,
            name: "",
            no_rm: ""
          }
        },
        stase: "",
        kompetensi: "",
        verifikasi: "",
        verifikasi_input: "",
        jenis_keterampilan: {id: null, nama: ""},
        tanggal: "",
        preceptor: {id: null, nama: ""},
        pspd_id: null,
        pspd: "",
        diagnosis_set: [],
        pemeriksaan_set: [],
        tatalaksana_set: [],
        keterampilan_set: [],
        extra_data: {}
    };
    super(modelName, model, [], ["verifikasi_input"], modelPath);
  }

  getLoadData(data) {
    let aktivitas = super.getLoadData(data);
    aktivitas.verifikasi_input = aktivitas.verifikasi;
    return aktivitas;
  }

  getPayload() {
    let aktivitas = this.observables.aktivitas;
    let payLoadData = {catatan: aktivitas.catatan, verifikasi: aktivitas.verifikasi_input};
    return payLoadData;
  }
}

export default Aktivitas;
